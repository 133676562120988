




























import { Component, Vue } from "vue-property-decorator";
import CourseCard from "@/components/CourseCard.vue";
import firebase from "@/plugins/firebase";
import "@/types";

@Component({
  components: {
    CourseCard
  }
})
export default class FeaturedCourses extends Vue {
  courses: Course[] = [];

  getCourses(): void {
    this.courses = [];

    firebase
      .firestore()
      .collection("/apps/kahev-akademi/courses")
      .where("disabled", "==", false)
      .orderBy("startDate", "desc")
      .limit(6)
      .get()
      .then(query => {
        query.forEach(doc => {
          const newItem = {
            id: doc.id,
            code: doc.data().code,
            urlCode: doc.data().urlCode,
            name: doc.data().name,
            desc: doc.data().desc,
            img: doc.data().img,
            instructor: doc.data().instructor,
            startDate: doc.data().startDate.toDate(),
            finishDate: doc.data().finishDate
              ? doc.data().finishDate.toDate()
              : null,
            price: doc.data().price,
            disabled: doc.data().disabled
          };

          this.courses.push(newItem);
        });
      });
  }

  mounted(): void {
    this.getCourses();
  }
}
