












import { Component, Vue } from "vue-property-decorator";
import Calendar from "./Calendar.vue";
import Features from "./Features.vue";
import FeaturedCourses from "./FeaturedCourses.vue";

@Component({
  components: {
    Calendar,
    Features,
    FeaturedCourses
  }
})
export default class HomeView extends Vue {}
