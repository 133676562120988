
















import { Component, Vue } from "vue-property-decorator";

@Component
export default class Features extends Vue {
  items = [
    {
      icon: require("@/assets/icon-elearning.svg"),
      title: "Canlı Eğitimler",
      text:
        "Zoom üzerinden gerçekleştirilen canlı eğitimlerle yer ve zaman bağımsız çevrimiçi öğrenmenin keyfini yaşayın."
    },
    {
      icon: require("@/assets/icon-videoconference.svg"),
      title: "Uzman Eğitmenler",
      text:
        "KAHEV organizasyonu aracılığıyla alanında uzman eğitmenlerden pek çok farklı konuda güncel bilgiler edinin."
    },
    {
      icon: require("@/assets/icon-award.svg"),
      title: "Katılım Belgesi",
      text:
        "Her eğitimin sonunda katılımcılara KAHEV Akademi onaylı dijital katılım belgesi gönderilecektir."
    }
  ];

  items_old = [
    {
      icon: require("@/assets/icon-elearning.svg"),
      title: "e-Öğrenme",
      text:
        "Güncel uzaktan eğitim teknikleriyle hazırladığımız öztempolu online eğitimler ile bağımsız öğrenmeyi keşfedeceksiniz."
    },
    {
      icon: require("@/assets/icon-award.svg"),
      title: "Atölye Çalışmaları",
      text:
        "Sosyal öğrenme teknikleriyle harmanladığımız çevrimiçi atölye çalışmalarında, kendinizi geliştirebilirsiniz."
    },
    {
      icon: require("@/assets/icon-videoconference.svg"),
      title: "Seminerler",
      text:
        "Düzenli aralıklarla gerçekleştirilen online semilerlerde alan uzmanlarından güncel bilgiler alabilirsiniz."
    }
  ];
}
